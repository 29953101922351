import { slugify } from 'transliteration';
import { trim } from 'lodash';

export const osmosysSlugify = (str: string, shouldTrim: boolean = true) => {
  const s = slugify(str, {
    allowedChars: 'a-zA-Z0-9-',
    lowercase: true,
  });
  return shouldTrim ? trim(s, '-') : s;
};
