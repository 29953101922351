import React from 'react';
import ReactDOM from 'react-dom';

import { OsmosysUi } from '@osmosys/ui';

import '@osmosys/sass/src/index.scss';

const main = () => {
  ReactDOM.render(
    <OsmosysUi development={process.env.NODE_ENV === 'development'} />,
    document.getElementById('root')
  );
};

main();

if (module.hot) {
  module.hot.accept('@osmosys/ui', () => {
    main();
    // // eslint-disable-next-line @typescript-eslint/no-var-requires
    // const NextAppOuter = require('./comps/AppOuter');
    // logger.info('ACCEPTED HOT CHANGE. RENDERING OSMOSYS...');
    // ReactDOM.render(<NextAppOuter />, document.getElementById('root'));
  });
}
