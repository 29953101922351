import crypto from 'crypto';
import { logger } from '@osmosys/logger';
import base64url from 'base64url';

export const getTransactionToken = (): Promise<string> => {
  return new Promise<string>(resolve =>
    crypto.randomBytes(48, (error, buf) => {
      if (error) {
        logger.error({ error }, 'Error in getTransactionToken');
        throw new Error('Internal error: Unable to get transaction token');
      }
      resolve(base64url(buf).replace(/-|_/gm, ''));
    })
  );
};
