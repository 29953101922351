export const groupAdjacent = <T extends any>(
  array: T[],
  cb: (prev: T, cur: T) => boolean
): T[][] => {
  return array.reduce<T[][]>((prev, cur) => {
    if (prev.length > 0) {
      const group = prev[prev.length - 1];
      const last = group[group.length - 1];
      if (cb(last, cur)) {
        // Split, create a new group.
        prev.push([cur]);
      } else {
        // Put into the current group.
        group.push(cur);
      }
    } else {
      // The first group.
      prev.push([cur]);
    }
    return prev;
  }, []);
};
