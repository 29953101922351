export class UserError extends Error {
  public IsUserError: boolean;
  constructor(...args: any[]) {
    super(...args);
    this.name = 'Error';
    this.message = args[0];
    this.IsUserError = true;
    Error.captureStackTrace(this, Error);
  }
}
