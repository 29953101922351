import MurmurHash3 from 'imurmurhash';

export const uniqueSlug = (uniq: string) => {
  if (uniq) {
    const hash = new MurmurHash3(uniq);
    const returnVal = ('00000000' + hash.result().toString(16)).substr(-8);
    // console.trace(`uniqueSlug:  ${uniq} -> ${returnVal}`);
    return returnVal;
  } else {
    return (Math.random().toString(16) + '0000000').substr(2, 8);
  }
};
